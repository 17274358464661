import type { FeatureConfig } from '@dx-ui/queries-dx-shop-search-ui';
import { ENTERPRISE_THEME_REFRESH_TOGGLE_NAME } from '@dx-ui/framework-theme';

export const FEATURE_TOGGLES: string[] = [
  'config-dream-attributes',
  'rule-ui-translate-urls',
  // SMB MVP (Context switching)
  'WEBPLAT-551',
  'NHCSEARCH-4655-blob-poc',
  'SearchDrawbridge',
  'search-ui-sale-filter',
  'config-rule-search-ui-suggested-filters',
  'config-renovated-properties',
  'smbMultiBusiness',
  'search-ui-config-brand-formal-name', //NHCSEARCH-5280
  'enable-genai-chat-ui',
  ENTERPRISE_THEME_REFRESH_TOGGLE_NAME,
];

export type Toggle = {
  config: FeatureConfig['config'];
  enabled: boolean;
  configFromRule?: FeatureConfig['config'];
};
export type NormalizedToggles = {
  [key: string]: Toggle;
};
